import React from 'react';
import HandBookImage from '../../../../assets/images/handbook.jpg';

import HandbookConfirmationImg from '../../../../assets/handbook_confirmation_image.png';
import HandbookConfirmationPdf from '../../../../assets/handbook_confirmation.pdf';
import resourceBookletImage from '../../../../assets/resourceBookletImage.png';
import resourceBookletImagePdf from '../../../../assets/resourceBookletPdf.pdf';
import {Link} from 'react-router-dom';
import handBookPdf from 'assets/DysmateHåndbok_v1.1_compressed.pdf';

import './styles.scss';
import strings from 'utils/strings';

class HandBook extends React.Component {
	constructor() {
		super();
		this.state = {};
	}

	render() {
		const hasLocation =
			this.props.location !== 'undefined' ? this.props.location : null;

		const hasPathName = hasLocation ? hasLocation.pathname : null;
		const isLibraryPath =
			hasLocation && this.props.location.pathname === '/bibliotek'
				? true
				: null;
		return (
			<div
				className={
					!isLibraryPath
						? 'lit-handbook-main'
						: 'lit-handbook-main_library-style'
				}
			>
				{hasPathName ? (
					<h1 className="lit-handbook-title">
						{strings.formatString(strings.handbooktitle, <></>)}
					</h1>
				) : (
					<></>
				)}
				{!isLibraryPath && (
					<>
						<span>
							<a href={handBookPdf} target="_blank">
								<img
									className="lit-handbook-img"
									src={HandBookImage}
								/>
							</a>
						</span>
						<span className="lit-handbook-title-link">
							<Link
								to={handBookPdf}
								target="_blank"
								rel="noopener noreferrer"
							>
								Håndbok Dysmate
							</Link>
						</span>
					</>
				)}

				{isLibraryPath && (
					<div className="lit-library-content-wrapper">
						<div className="lit-library-content-wrapper__row">
							<a href={handBookPdf} target="_blank">
								<img
									className="lit-lib-handbook-img"
									src={HandBookImage}
								/>
							</a>

							<div className="lit-lib-handbook-title-link">
								<Link
									to={handBookPdf}
									target="_blank"
									rel="noopener noreferrer"
								>
									Håndbok Dysmate
								</Link>
							</div>
						</div>
						<div className="lit-library-content-wrapper__row">
							<a href={HandbookConfirmationPdf} target="_blank">
								<img
									className="lit-lib-handbook-img"
									src={HandbookConfirmationImg}
								/>
							</a>

							<div className="lit-lib-handbook-title-link">
								<Link
									to={HandbookConfirmationPdf}
									target="_blank"
									rel="noopener noreferrer"
								>
									Bekreftelse på dysleksi
								</Link>
							</div>
						</div>
						<div className="lit-library-content-wrapper__row">
							<a href={resourceBookletImagePdf} target="_blank">
								<img
									className="lit-lib-handbook-img"
									src={resourceBookletImage}
								/>
							</a>
							<div className="lit-lib-handbook-title-link">
								<Link
									to={resourceBookletImagePdf}
									target="_blank"
									rel="noopener noreferrer"
								>
									Ressurshefte Dysmate
								</Link>
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
}
export default HandBook;
